import React from "react";
import "./App.scss";
import Column from "./components/Column";
import HeaderAndText from "./components/HeaderAndText";
import Work from "./components/Work";
import Works from "./assets/howworks.json";
import profilePicture from "./assets/profile.jpg";

function App() {
	return (
		<div className="App">
			<header className="header">
				<div className="content-wrapper">
					<h1>Joel Janson Johansen</h1>
					<p>composer / musician / interneter</p>
				</div>
			</header>
			<div className="column-wrapper">
				<Column
					component={
						<HeaderAndText
							title="About"
							text={
								<div>
									<p>
										All other things in the name of music. Working mostly with
										acoustic instruments, trying to make them collaborate with
										the digital society we live in. This means trying to
										incorporate smartphones and other devices into the music
										without loosing the music itself. Composer, producer,
										musician and developer.
										<br />
										<br />
										Studied composition for 2 years at Tonsättarskolan in Visby
										(studied for Henrik Strindberg, Per Mårtensson, Mattias
										Svensson Sandell), then a Bachelor in composition from the
										Royal College of Music in Stockholm (studied for Marie
										Samuelsson and Fredrik Hedelin). Currently studying a
										masters in composition at the Royal College of Music in
										Stockholm.
									</p>
								</div>
							}
						/>
					}
				></Column>
				<Column
					component={[
						<HeaderAndText title="Work" text="" />,
						Works.nieces.map((work) => {
							if (!work.pieces.length) return "";
							return <Work key={work.title} work={work} />;
						}),
					]}
				></Column>
				<Column
					component={[
						<HeaderAndText title="More work" text="" />,
						Works.pieces.map((work) => {
							if (!work.pieces.length) return "";
							return <Work key={work.title} work={work} />;
						}),
					]}
				></Column>
				<Column
					component={
						<HeaderAndText
							title="Contact"
							text={
								<div className="contact-wrapper">
									<img alt="Joel Janson phoning" src={profilePicture} />
									<p>
										Get in touch for projects, ideas, collaborations and
										questions.
									</p>
									<a
										target="_blank"
										href="https://github.com/joeljanson"
										rel="noreferrer"
									>
										Github
									</a>
									<a
										target="_blank"
										href="https://www.instagram.com/joeljanson/"
										rel="noreferrer"
									>
										Instagram
									</a>
									<a
										target="_blank"
										href="https://soundcloud.com/joel-janson-johansen"
										rel="noreferrer"
									>
										Soundcloud
									</a>
									<a href="mailto:joel.janson.johansen@gmail.com">Email</a>
								</div>
							}
						/>
					}
				></Column>
			</div>
		</div>
	);
}

export default App;
