import React from "react";
import "../App.scss";

type WorkProps = {
	work: any;
};

function Work({ work }: WorkProps) {
	console.log(work.pieces);
	function validate(name: string, content: string) {
		if (!content) return;
		return content.length > 0 ? (
			<p>
				<span>{name + ": "}</span>
				{content}
			</p>
		) : (
			""
		);
	}
	function validateLink(name: string, content: string) {
		if (!content) return;
		return content.length > 0 ? (
			<a href={content} target="_blank" rel="noreferrer">
				{name}
			</a>
		) : (
			""
		);
	}
	return (
		<div className="work-category">
			<h2>{work.title}</h2>
			{work.pieces.map((piece: any) => {
				return (
					<div className="single-piece" key={piece.title}>
						<p className="title">{piece.title}</p>
						{validate("Instrumentation", piece.instrumentation)}
						{validate("Duration", piece.duration)}
						{validate("Text", piece.text)}
						{validate("Parts", piece.parts)}
						{validate("Premiere", piece.premiere)}
						{validate("About", piece.about)}
						{validate("Commission", piece.commission)}
						{validate("Awards", piece.awards)}
						{validate("Other", piece.other)}
						{validateLink("Link to audio", piece.audiolink)}
						{validateLink("Link to video", piece.videolink)}
					</div>
				);
			})}
		</div>
	);
}

export default Work;
