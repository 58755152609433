import React from "react";
import "../App.scss";

type HeaderAndTextProps = {
	title: string;
	text: string | any;
};

function HeaderAndText({ title, text }: HeaderAndTextProps) {
	return (
		<div>
			<h1>{title}</h1>
			<div>{typeof text === "string" ? <p>{text}</p> : text}</div>
		</div>
	);
}

export default HeaderAndText;
