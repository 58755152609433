import React from "react";
import "../App.scss";

type ColumnProps = {
	component: [] | any;
};

function Column({ component }: ColumnProps) {
	return (
		<div key={Math.random()} className="column">
			<div className="content">{component}</div>
		</div>
	);
}

export default Column;
